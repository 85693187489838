<template>
  <BCol
    md="12"
    lg="6"
    cols="12"
  >
    <BCard body-class="h-[370px]">
      <div class="flex flex-row justify-between items-center">
        <span class="text-black font-semibold text-xl">Transaksi Terakhir Kartu</span>
        <BImg
          src="https://storage.googleapis.com/komerce/assets/icons/arrow-right-2.svg"
          class="border-solid border-2 rounded-md cursor-pointer"
          @click="$router.push('/komcards')"
        />
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          :items="items"
          :fields="fields"
          class="mt-1"
          empty-text="Belum ada transaksi terakhir"
          responsive
          show-empty
        >
          <template #cell(desc)="data">
            <div class="text-black font-medium">
              {{ data.item.description }}
            </div>
            <span class="text-[#828282]">{{ DDMMYYYY(data.item.date) }} - {{ TIME(data.item.date) }}</span>
          </template>
          <template #cell(card)="data">
            <div class="flex items-center">
              <BImg
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card-pos.svg"
              />
              <div class="ml-[5px]">
                {{ data.item.card.card_label }}
              </div>
            </div>
          </template>
          <template #cell(amount)="data">
            <div
              v-if="data.item.is_kompoints"
              class="flex justify-center items-center gap-3"
            >
              <img
                src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
                alt="Komerce"
                width="20"
              >
              <div>{{ data.item.amount }}</div>
            </div>
            <div
              v-else
              :class="nominalClass(data.item.transaction_type)"
            >
              <span v-if="data.item.transaction_type === 'credit'">+{{ IDR(data.item.amount) }}</span>
              <div v-if="data.item.transaction_type === 'debit'">
                -{{ IDR(data.item.amount) }}
              </div>
              <div
                v-if="data.item.transaction_type === 'cashback'"
                class="flex items-center justify-center"
              >
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/Kompoints.svg"
                  alt="Komerce"
                  width="20"
                >
                <div class="ml-[5px]">
                  {{ IDR(data.item.amount) }}
                </div>
              </div>
            </div>
          </template>
        </BTable>
      </BOverlay>
    </BCard>
  </BCol>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { DDMMYYYY, TIME } from '@/libs/formatDate'
import { IDR } from '@/libs/currency'

export default {
  data() {
    return {
      DDMMYYYY,
      IDR,
      TIME,
      loading: false,
      items: [],
      fields: [
        {
          key: 'desc',
          label: 'Deskripsi',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
            minWidth: '200px',
          },
          tdClass: 'text-black',
          thClass: 'font-semibold',
          class: 'p-1',
        },
        {
          key: 'card',
          label: 'Kartu Komcards',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
            minWidth: '200px',
          },
          tdClass: 'text-black',
          thClass: 'font-semibold',
          class: 'p-1',
        },
        {
          key: 'amount',
          label: 'Nominal',
          thStyle: {
            textTransform: 'capitalize',
            fontSize: '13px',
            color: 'black',
            minWidth: '200px',
          },
          tdClass: 'text-black',
          thClass: 'font-semibold',
          class: 'p-1',
        },
      ],
    }
  },
  mounted() {
    this.getMutation()
  },
  methods: {
    async getMutation() {
      this.loading = true
      const url = '/komcards/api/v1/card/mutation?status=success&limit=3'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.items = data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal load data mutasi' })
        })
    },
    statusClass(status) {
      if (status === 'success') {
        return 'text-[#34A770]'
      }
      if (status === 'failed') {
        return 'text-[#E31A1A]'
      }
      if (status === 'pending') {
        return 'text-[#828282]'
      }
      return ''
    },
    nominalClass(type) {
      if (type === 'credit') {
        return 'text-[#34A770]'
      }
      if (type === 'debit') {
        return 'text-[#E31A1A]'
      }
      if (type === 'cashback') {
        return 'text-[#CC9212]'
      }
      return ''
    },
  },
}
</script>
